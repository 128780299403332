import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Link from './Link';
import { GhostLink } from './Buttons';
import PostTags from './PostTags';

const StyledCardImage = styled.div`
  width: 100%;
  overflow: hidden;

  div {
    transform: scale(1);
    transition: all 0.2s ease-in-out;
  }

  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    width: ${props => (props.horizontal ? '50%' : '100%')};
  }
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: ${props => props.theme.colors.white};
  border-bottom: 4px solid ${props => props.theme.colors.accent};
  border-radius: 0 0 3px 3px;
  box-shadow: ${props => props.theme.shadows.large};

  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    flex-direction: ${props => (props.horizontal ? 'row' : 'column')};
  }

  &:hover,
  &:focus {
    ${StyledCardImage} div {
      transform: scale(1.1);
    }
  }
`;

const StyledCardTitle = styled.h2`
  margin: 0 0 1rem;
  align-self: ${props => (props.titleCentered ? 'center' : 'flex-start')};
  font-size: ${props => (props.large ? '2rem' : '1.15rem')};
  text-align: ${props => (props.titleCentered ? 'center' : 'left')};
`;

const StyledCardTitleLink = styled(Link)`
  text-decoration: none;
`;

const StyledCardBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
  padding: ${props => (props.large ? '2rem' : '1rem')};

  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    width: ${props => (props.horizontal ? '50%' : '100%')};
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const StyledCardText = styled.div`
  margin-bottom: 1rem;

  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    margin-bottom: ${props => (props.horizontal ? '1rem' : '1rem')};
  }
`;

const StyledGhostLink = styled(GhostLink)`
  margin-top: auto;
  flex-grow: 0;
`;

const PostCard = props => (
  <StyledCard horizontal={props.horizontal} large={props.large}>
    <StyledCardImage horizontal={props.horizontal}>
      {props.image && props.link && <Link to={props.link} title={props.title}>{props.image}</Link>}
      {!props.link && props.image && props.image}
      {!props.image && (
        <Img className="site-logo" fluid={props.file.childImageSharp.fluid} alt="" />
      )}
    </StyledCardImage>
    <StyledCardBody horizontal={props.horizontal} large={props.large}>
      {props.tags && <PostTags tags={props.tags} />}

      <StyledCardTitle large={props.large} titleCentered={props.titleCentered || false}>
        {props.link && (
          <StyledCardTitleLink to={props.link} dangerouslySetInnerHTML={{ __html: props.title }} />
        )}
        {!props.link && <span dangerouslySetInnerHTML={{ __html: props.title }} />}
      </StyledCardTitle>

      {props.text && <StyledCardText horizontal={props.horizontal}>{props.text}</StyledCardText>}

      {!props.text && props.children}

      {props.link && props.actionButtonLabel && (
        <StyledGhostLink to={props.link}>{props.actionButtonLabel}</StyledGhostLink>
      )}
    </StyledCardBody>
  </StyledCard>
);

PostCard.propTypes = {
  horizontal: PropTypes.bool,
  image: PropTypes.any,
  link: PropTypes.string,
};

PostCard.defaultProps = {
  horizontal: false,
  image: null,
  link: null,
};

const PostCardQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "chaloupka.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1280, maxHeight: 720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <PostCard {...props} {...data} />
    )}
  />
);

export default PostCardQuery;
