import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';

import Layout from '../components/Layout';
import { Section } from '../components/Section';
import Container from '../components/Container';
import PostCard from '../components/PostCard';

const FullHeightCard = styled(PostCard)`
  min-height: 100%;
`;

const Column = styled(Box)`
  margin: 0 0 2rem;

  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    margin: 0 1% 2rem;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(3n+1) {
      margin-left: 0;
    }
  }
`;

const Services = (props) => {
  const { data } = props;
  const { title: siteTitle } = data.site.siteMetadata;
  const { edges: services } = data.allWordpressWpMphbRoomService;

  return (
    <Layout>
      <Helmet title={`Naše služby | ${siteTitle}`} />
      <Section>
        <Container>
          <h1>Naše služby</h1>

          <p>
            Pro zpříjemnění vašeho pobytu jsme připravili výběr toho nejlepšího.
            <br />
            Od výborného lokálního jídla, přes vybraná francouzská vína z rodinného vinařství
            až po uspořádání událostí dle vašeho přání.
          </p>

          <Flex alignItems={['stretch']} flexDirection={['column', 'row']} flexWrap="wrap">
            {services.map((service) => {
              const post = service.node;

              return (
                <Column key={post.id} width={[1, 1 / 2, '32%']} mb={['2rem', '0']}>
                  <FullHeightCard
                    style={{ height: '100%' }}
                    image={post.featured_media && (
                      <Img
                        fluid={post.featured_media.localFile.childImageSharp.fluid}
                        alt={post.featured_media.alt_text}
                      />
                    )}
                    title={post.title}
                    link={`/nase-sluzby/${post.slug}/`}
                    text={post.acf.price && `Cena: ${post.acf.price}`}
                    actionButtonLabel="Číst dále →"
                  />
                </Column>
              );
            })}
          </Flex>
        </Container>
      </Section>
    </Layout>
  );
};

export default Services;

export const pageQuery = graphql`
  query ServicesPage {
    allWordpressWpMphbRoomService(sort: {order: ASC, fields: menu_order}) {
      edges {
        node {
          acf {
            price
          }
          id
          title
          content
          slug
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 640, maxHeight: 360) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
